import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'GPortas | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Software Engineer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '👋 Hey! my name is ',
  name: 'Guillermo Portas',
  subtitle: "I'm a Software Engineer.",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I am a pragmatic Software Engineer with a strong product and business orientation 🎯',
  paragraphTwo:
    'For the past several years, I have focused on helping companies and institutions build, launch, and evolve their digital products 🚀',
  paragraphThree:
    "I am passionate about creating incredible products that improve people's lives and the world of tomorrow 🌎",
  paragraphFour:
    '🧱 “For each well placed brick, we will avoid at least three badly placed bricks that will be needed to build the castle.” 🏰',
  resume: 'https://blog.gportas.me/', // if no blog, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'dataverse.png', // From https://opengraph.githubassets.com/4d1364b31448d6d6e752bb54574957a5cd45428a8cbff8a6341454c2eddb6735/IQSS/dataverse
    title: 'Dataverse',
    info: 'I actively contribute to Dataverse, an open source research data repository software.',
    info2:
      'I am mainly focused on re-architecture matters. In particular, the decoupling of the frontend and migration to a React SPA, in addition to other topics such as authentication, API extension, and, more recently, containerization.',
    repo: 'https://github.com/IQSS/dataverse',
  },
  {
    id: nanoid(),
    img: 'drs-import-management-service.png', // From https://opengraph.githubassets.com/53108c3bb11ede82dda3fe8891aa380a25f23943df705476fbabb4f7a20d662f/harvard-lts/drs-import-management-service
    title: 'Harvard',
    info: 'I contributed to a Harvard project related with the development of a data import/preservation system.',
    info2:
      'My biggest contribution has been the development of drs-import-management-service, a Python service that provides a REST API and orchestrates different components within the system.',
    repo: 'https://github.com/harvard-lts/drs-import-management-service',
  },
  {
    id: nanoid(),
    img: 'gazella.png',
    title: 'Gazella',
    info: 'Gazella is a mobile health application for women that includes personalized workouts and unique recipes adapted to the phases of the menstrual cycle and the hormonal stages of women.',
    info2:
      'I have evolved and maintained this product in all its technical aspects: mobile app, backend and infrastructure.',
    url: 'https://www.gazella.app/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '🙋🏽‍♂️ How can I help you?',
  btn: "Let's talk",
  email: 'hey@gportas.me',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/gportas/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/GPortas',
    },
    {
      id: nanoid(),
      name: 'gitlab',
      url: 'https://gitlab.com/GPortas',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
